exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-academics-js": () => import("./../../../src/pages/academics.js" /* webpackChunkName: "component---src-pages-academics-js" */),
  "component---src-pages-admissions-js": () => import("./../../../src/pages/admissions.js" /* webpackChunkName: "component---src-pages-admissions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-school-life-js": () => import("./../../../src/pages/school-life.js" /* webpackChunkName: "component---src-pages-school-life-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

